var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{staticClass:"d-md-block",attrs:{"sm":"12","lg":"12"}},[_c('CCard',{staticClass:"zq-card-table zq-card-table--with-header"},[_c('CCardHeader',{staticStyle:{"font-size":"21px"}},[_vm._v(" Live Achievements triggered ")]),(_vm.activeAchievements.length)?_c('CCardBody',[_c('CDataTable',{staticClass:"zq--table",attrs:{"hover":"","items":_vm.activeAchievements,"fields":_vm.tableFields,"head-color":"light","no-sorting":""},scopedSlots:_vm._u([{key:"icons",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('div',{staticClass:"c-avatar"},[_c('img',{staticClass:"c-avatar-img size30",attrs:{"src":_vm.getIcon(item.icon),"alt":""}})])])]}},{key:"achievementName",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"achievementId",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(item.id))])])]}},{key:"scheduleType",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.scheduling.scheduleType)+" ")])]}},{key:"startDate",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.dateFormate(item.scheduling.startDate))+" ")])]}}],null,false,4225413610)})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }