<template>
  <CCard class="active-players">
    <CCardBody>
      <CRow>
        <CCol sm="6" lg="6">
          <h4 class="card-title mb-3">P&L</h4>
          <CDataTable
              class="zq--table--dashboard zq--table"
              hover
              :items="products"
              :fields="productFields"
              head-color="light"
              no-sorting
          >
            <template #rank-header>
              <div>Top 25 Games</div>
            </template>
            <template #pNL-header>
              <div>P&L</div>
            </template>
            <td slot="productName" slot-scope="{item}">
              <div>
                {{ item["product name"] }}
              </div>
            </td>
            <td slot="pNL" slot-scope="{item}">
              <div>
                {{ item[status] }}
              </div>
            </td>
          </CDataTable>
        </CCol>

        <CCol sm="6" lg="6">

          <CChartLine
              style="height:400px"
              :labels=productLabels
              :datasets="[
                 {
                    data: productDatasets,
                    backgroundColor: '#01a3bb73',
                    label: 'P&L',
                    borderColor:'#01A3BB',
                    lineTension:0
                  }
                ]"

              :options="{ maintainAspectRatio: false ,
                   scales: {
                    yAxes: [{
                    min: 40

            }],
        }}"
          ></CChartLine>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>

</template>

<script>
import {CChartLine} from '@coreui/vue-chartjs'

export default {
  name: "ProfitLoss",
  components: {
    CChartLine
  },
  props: {status: String,},
  data() {
    return {
      products: [{
        "rank": 1,
        "product name": "fruits",
        "Last Hour": 8610,
        "Last 3 Hours": 25830,
        "Last 6 Hours": 77490,
        "Last 12 Hours": 232470,
        "Last 24 Hours": 387450,
        "Last 48 Hours": 697410,
        "Last Week": 3487050,
        "Last 28 Days": 15343020
      },
        {
          "rank": 2,
          "product name": "doubleroulette",
          "Last Hour": 7700,
          "Last 3 Hours": 23100,
          "Last 6 Hours": 69300,
          "Last 12 Hours": 207900,
          "Last 24 Hours": 346500,
          "Last 48 Hours": 623700,
          "Last Week": 3118500,
          "Last 28 Days": 13721400
        },
        {
          "rank": 3,
          "product name": "panthersreign",
          "Last Hour": 7665,
          "Last 3 Hours": 22995,
          "Last 6 Hours": 68985,
          "Last 12 Hours": 206955,
          "Last 24 Hours": 344925,
          "Last 48 Hours": 620865,
          "Last Week": 3104325,
          "Last 28 Days": 13659030
        },
        {
          "rank": 4,
          "product name": "ghostglyph",
          "Last Hour": 5880,
          "Last 3 Hours": 17640,
          "Last 6 Hours": 52920,
          "Last 12 Hours": 158760,
          "Last 24 Hours": 264600,
          "Last 48 Hours": 476280,
          "Last Week": 2381400,
          "Last 28 Days": 10478160
        },
        {
          "rank": 5,
          "product name": "pimpelipom",
          "Last Hour": 4235,
          "Last 3 Hours": 12705,
          "Last 6 Hours": 38115,
          "Last 12 Hours": 114345,
          "Last 24 Hours": 190575,
          "Last 48 Hours": 343035,
          "Last Week": 1715175,
          "Last 28 Days": 7546770
        },
        {
          "rank": 6,
          "product name": "moneytrain",
          "Last Hour": 4193,
          "Last 3 Hours": 12579,
          "Last 6 Hours": 37737,
          "Last 12 Hours": 113211,
          "Last 24 Hours": 188685,
          "Last 48 Hours": 339633,
          "Last Week": 1698165,
          "Last 28 Days": 7471926
        },
        {
          "rank": 7,
          "product name": "normans",
          "Last Hour": 3801,
          "Last 3 Hours": 11403,
          "Last 6 Hours": 34209,
          "Last 12 Hours": 102627,
          "Last 24 Hours": 171045,
          "Last 48 Hours": 307881,
          "Last Week": 1539405,
          "Last 28 Days": 6773382
        },
        {
          "rank": 8,
          "product name": "sunset",
          "Last Hour": 3738,
          "Last 3 Hours": 11214,
          "Last 6 Hours": 33642,
          "Last 12 Hours": 100926,
          "Last 24 Hours": 168210,
          "Last 48 Hours": 302778,
          "Last Week": 1513890,
          "Last 28 Days": 6661116
        },
        {
          "rank": 9,
          "product name": "jokerjackpot",
          "Last Hour": 861,
          "Last 3 Hours": 2583,
          "Last 6 Hours": 7749,
          "Last 12 Hours": 23247,
          "Last 24 Hours": 38745,
          "Last 48 Hours": 69741,
          "Last Week": 348705,
          "Last 28 Days": 1534302
        },
        {
          "rank": 10,
          "product name": "towertumble",
          "Last Hour": 714,
          "Last 3 Hours": 2142,
          "Last 6 Hours": 6426,
          "Last 12 Hours": 19278,
          "Last 24 Hours": 32130,
          "Last 48 Hours": 57834,
          "Last Week": 289170,
          "Last 28 Days": 1272348
        },
        {
          "rank": 11,
          "product name": "goldlab",
          "Last Hour": 630,
          "Last 3 Hours": 1890,
          "Last 6 Hours": 5670,
          "Last 12 Hours": 17010,
          "Last 24 Hours": 28350,
          "Last 48 Hours": 51030,
          "Last Week": 255150,
          "Last 28 Days": 1122660
        },
        {
          "rank": 12,
          "product name": "bbw",
          "Last Hour": 595,
          "Last 3 Hours": 1785,
          "Last 6 Hours": 5355,
          "Last 12 Hours": 16065,
          "Last 24 Hours": 26775,
          "Last 48 Hours": 48195,
          "Last Week": 240975,
          "Last 28 Days": 1060290
        },
        {
          "rank": 13,
          "product name": "wildsociety",
          "Last Hour": 588,
          "Last 3 Hours": 1764,
          "Last 6 Hours": 5292,
          "Last 12 Hours": 15876,
          "Last 24 Hours": 26460,
          "Last 48 Hours": 47628,
          "Last Week": 238140,
          "Last 28 Days": 1047816
        },
        {
          "rank": 14,
          "product name": "megaflip",
          "Last Hour": 504,
          "Last 3 Hours": 1512,
          "Last 6 Hours": 4536,
          "Last 12 Hours": 13608,
          "Last 24 Hours": 22680,
          "Last 48 Hours": 40824,
          "Last Week": 204120,
          "Last 28 Days": 898128
        },
        {
          "rank": 15,
          "product name": "musketeers",
          "Last Hour": 420,
          "Last 3 Hours": 1260,
          "Last 6 Hours": 3780,
          "Last 12 Hours": 11340,
          "Last 24 Hours": 18900,
          "Last 48 Hours": 34020,
          "Last Week": 170100,
          "Last 28 Days": 748440
        },
        {
          "rank": 16,
          "product name": "tigersglory",
          "Last Hour": 385,
          "Last 3 Hours": 1155,
          "Last 6 Hours": 3465,
          "Last 12 Hours": 10395,
          "Last 24 Hours": 17325,
          "Last 48 Hours": 31185,
          "Last Week": 155925,
          "Last 28 Days": 686070
        },
        {
          "rank": 17,
          "product name": "polarpaws",
          "Last Hour": 350,
          "Last 3 Hours": 1050,
          "Last 6 Hours": 3150,
          "Last 12 Hours": 9450,
          "Last 24 Hours": 15750,
          "Last 48 Hours": 28350,
          "Last Week": 141750,
          "Last 28 Days": 623700
        },
        {
          "rank": 18,
          "product name": "jamminjars",
          "Last Hour": 336,
          "Last 3 Hours": 1008,
          "Last 6 Hours": 3024,
          "Last 12 Hours": 9072,
          "Last 24 Hours": 15120,
          "Last 48 Hours": 27216,
          "Last Week": 136080,
          "Last 28 Days": 598752
        },
        {
          "rank": 19,
          "product name": "mars",
          "Last Hour": 329,
          "Last 3 Hours": 987,
          "Last 6 Hours": 2961,
          "Last 12 Hours": 8883,
          "Last 24 Hours": 14805,
          "Last 48 Hours": 26649,
          "Last Week": 133245,
          "Last 28 Days": 586278
        },
        {
          "rank": 20,
          "product name": "redtemple2",
          "Last Hour": 280,
          "Last 3 Hours": 840,
          "Last 6 Hours": 2520,
          "Last 12 Hours": 7560,
          "Last 24 Hours": 12600,
          "Last 48 Hours": 22680,
          "Last Week": 113400,
          "Last 28 Days": 498960
        },
        {
          "rank": 21,
          "product name": "ironbank",
          "Last Hour": 224,
          "Last 3 Hours": 672,
          "Last 6 Hours": 2016,
          "Last 12 Hours": 6048,
          "Last 24 Hours": 10080,
          "Last 48 Hours": 18144,
          "Last Week": 90720,
          "Last 28 Days": 399168
        },
        {
          "rank": 22,
          "product name": "slotvegas",
          "Last Hour": 210,
          "Last 3 Hours": 630,
          "Last 6 Hours": 1890,
          "Last 12 Hours": 5670,
          "Last 24 Hours": 9450,
          "Last 48 Hours": 17010,
          "Last Week": 85050,
          "Last 28 Days": 374220
        },
        {
          "rank": 23,
          "product name": "volsunga",
          "Last Hour": 196,
          "Last 3 Hours": 588,
          "Last 6 Hours": 1764,
          "Last 12 Hours": 5292,
          "Last 24 Hours": 8820,
          "Last 48 Hours": 15876,
          "Last Week": 79380,
          "Last 28 Days": 349272
        },
        {
          "rank": 24,
          "product name": "saunapeli",
          "Last Hour": 189,
          "Last 3 Hours": 567,
          "Last 6 Hours": 1701,
          "Last 12 Hours": 5103,
          "Last 24 Hours": 8505,
          "Last 48 Hours": 15309,
          "Last Week": 76545,
          "Last 28 Days": 336798
        },
        {
          "rank": 25,
          "product name": "highvoltage",
          "Last Hour": 175,
          "Last 3 Hours": 525,
          "Last 6 Hours": 1575,
          "Last 12 Hours": 4725,
          "Last 24 Hours": 7875,
          "Last 48 Hours": 14175,
          "Last Week": 70875,
          "Last 28 Days": 311850
        }],
      productFields: [
        {key: 'rank'},
        {key: 'productName'},
        {key: 'pNL'},
      ],
    }
  },
  created() {

  },
  computed: {
    productDatasets() {
      var currentStatus = this.status;
      var p = [];
      this.products.forEach(function (product) {
        p.push(product[currentStatus]);
      });
      return p;
    },

    productLabels() {
      var p = [];
      this.products.forEach(function (product) {
        p.push(product["product name"] + "  " + product["rank"]);
      });
      return p;
    }
  },

}
</script>