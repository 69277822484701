<template>
  <CCard class="active-players">
    <CCardBody>
      <CRow>
        <CCol sm="6" lg="6">
          <h4  class="card-title mb-3">Unique Products</h4>
          <CDataTable
              class="zq--table--dashboard zq--table"
              hover
              :items="products"
              :fields="productFields"
              head-color="light"
              no-sorting
          >
            <template #rank-header>
              <div>Top 25 Member Groups</div>
            </template>
            <td slot="memberGroups" slot-scope="{item}">
              <div>
                {{item["Member groups"]}}
              </div>
            </td>
            <td slot="uniqueProducts" slot-scope="{item}">
              <div>
                {{item[status]}}
              </div>
            </td>
          </CDataTable>
        </CCol>
        <CCol sm="6" lg="6">

          <CChartHorizontalBar
              style="height:500px"
              type='horizontalBar'
              :labels="productLabels"
              :status="status"
              :datasets=" [{
                label: 'Member Groups',
                data: productDatasets,
                backgroundColor: '#e65f35',
                }]"
              :options="{
                maintainAspectRatio: false ,
                scales: {
                yAxes: [{
                 gridLines:{
                        drawBorder: false,
                        display:false,
                      },
                  },
                ],
                XAxes: [{
                ticks: {
                        min:0,
                        max:1400,
                  }
            }]
            }
              }"
          ></CChartHorizontalBar>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>

</template>

<script>
import {CChartHorizontalBar} from '@coreui/vue-chartjs'
export default {
  name: "UniquePlayers",
  components:{
    CChartHorizontalBar
  },
  props: { status: String,},
  data(){
    return{
      products:[{"rank":1,"Member groups":"CL","Last Hour":300,"Last 3 Hours":390,"Last 6 Hours":1170,"Last 12 Hours":2574,"Last 24 Hours":6435,"Last 48 Hours":14157,"Last Week":46718.1,"Last 28 Days":200887.83},
        {"rank":2,"Member groups":"relax[317]","Last Hour":285,"Last 3 Hours":370.5,"Last 6 Hours":1037.4,"Last 12 Hours":2282.28,"Last 24 Hours":5187,"Last 48 Hours":9336.6,"Last Week":30810.78,"Last 28 Days":132486.354},
        {"rank":3,"Member groups":"operator[random]","Last Hour":270,"Last 3 Hours":351,"Last 6 Hours":1053,"Last 12 Hours":2316.6,"Last 24 Hours":5265,"Last 48 Hours":9477,"Last Week":31274.1,"Last 28 Days":134478.63},
        {"rank":4,"Member groups":"environment[LUX]","Last Hour":265,"Last 3 Hours":344.5,"Last 6 Hours":1033.5,"Last 12 Hours":2273.7,"Last 24 Hours":5167.5,"Last 48 Hours":9301.5,"Last Week":30694.95,"Last 28 Days":131988.285},
        {"rank":5,"Member groups":"brandId[20]","Last Hour":255,"Last 3 Hours":331.5,"Last 6 Hours":994.5,"Last 12 Hours":2187.9,"Last 24 Hours":4972.5,"Last 48 Hours":8950.5,"Last Week":29536.65,"Last 28 Days":127007.595},
        {"rank":6,"Member groups":"country[CH]","Last Hour":250,"Last 3 Hours":325,"Last 6 Hours":975,"Last 12 Hours":2145,"Last 24 Hours":4875,"Last 48 Hours":8775,"Last Week":28957.5,"Last 28 Days":124517.25},
        {"rank":7,"Member groups":"parentPartner","Last Hour":192,"Last 3 Hours":249.6,"Last 6 Hours":748.8,"Last 12 Hours":1647.36,"Last 24 Hours":3744,"Last 48 Hours":6739.2,"Last Week":22239.36,"Last 28 Days":95629.248},
        {"rank":8,"Member groups":"free_player","Last Hour":182,"Last 3 Hours":236.6,"Last 6 Hours":709.8,"Last 12 Hours":1561.56,"Last 24 Hours":3549,"Last 48 Hours":6388.2,"Last Week":21081.06,"Last 28 Days":90648.558},
        {"rank":9,"Member groups":"demo","Last Hour":180,"Last 3 Hours":234,"Last 6 Hours":655.2,"Last 12 Hours":1441.44,"Last 24 Hours":3276,"Last 48 Hours":5896.8,"Last Week":19459.44,"Last 28 Days":83675.592},
        {"rank":10,"Member groups":"relax[150]","Last Hour":170,"Last 3 Hours":221,"Last 6 Hours":663,"Last 12 Hours":1458.6,"Last 24 Hours":3315,"Last 48 Hours":5967,"Last Week":19691.1,"Last 28 Days":84671.73},
        {"rank":11,"Member groups":"games","Last Hour":164,"Last 3 Hours":213.2,"Last 6 Hours":639.6,"Last 12 Hours":1407.12,"Last 24 Hours":3198,"Last 48 Hours":5756.4,"Last Week":18996.12,"Last 28 Days":81683.316},
        {"rank":12,"Member groups":"country[RU]","Last Hour":120,"Last 3 Hours":156,"Last 6 Hours":468,"Last 12 Hours":1029.6,"Last 24 Hours":2340,"Last 48 Hours":4212,"Last Week":13899.6,"Last 28 Days":59768.28},
        {"rank":13,"Member groups":"relax[240]","Last Hour":111,"Last 3 Hours":144.3,"Last 6 Hours":432.9,"Last 12 Hours":952.38,"Last 24 Hours":2164.5,"Last 48 Hours":3896.1,"Last Week":12857.13,"Last 28 Days":55285.659},
        {"rank":14,"Member groups":"relax[123]","Last Hour":108,"Last 3 Hours":140.4,"Last 6 Hours":421.2,"Last 12 Hours":926.64,"Last 24 Hours":2106,"Last 48 Hours":3790.8,"Last Week":12509.64,"Last 28 Days":53791.452},
        {"rank":15,"Member groups":"country[FI]","Last Hour":99,"Last 3 Hours":128.7,"Last 6 Hours":386.1,"Last 12 Hours":849.42,"Last 24 Hours":1930.5,"Last 48 Hours":3474.9,"Last Week":11467.17,"Last 28 Days":49308.831},
        {"rank":16,"Member groups":"relax[110]","Last Hour":88,"Last 3 Hours":114.4,"Last 6 Hours":343.2,"Last 12 Hours":755.04,"Last 24 Hours":1716,"Last 48 Hours":3088.8,"Last Week":10193.04,"Last 28 Days":43830.072},
        {"rank":17,"Member groups":"country[SE]","Last Hour":78,"Last 3 Hours":101.4,"Last 6 Hours":283.92,"Last 12 Hours":624.624,"Last 24 Hours":1419.6,"Last 48 Hours":2555.28,"Last Week":8432.424,"Last 28 Days":36259.4232},
        {"rank":18,"Member groups":"relax[11]","Last Hour":77,"Last 3 Hours":100.1,"Last 6 Hours":300.3,"Last 12 Hours":660.66,"Last 24 Hours":1501.5,"Last 48 Hours":2702.7,"Last Week":8918.91,"Last 28 Days":38351.313},
        {"rank":19,"Member groups":"relax[222]","Last Hour":62,"Last 3 Hours":80.6,"Last 6 Hours":241.8,"Last 12 Hours":531.96,"Last 24 Hours":1209,"Last 48 Hours":2176.2,"Last Week":7181.46,"Last 28 Days":30880.278},
        {"rank":20,"Member groups":"country[NL]","Last Hour":55,"Last 3 Hours":71.5,"Last 6 Hours":200.2,"Last 12 Hours":440.44,"Last 24 Hours":1001,"Last 48 Hours":1801.8,"Last Week":5945.94,"Last 28 Days":25567.542},
        {"rank":21,"Member groups":"country[MT]","Last Hour":43,"Last 3 Hours":55.9,"Last 6 Hours":167.7,"Last 12 Hours":368.94,"Last 24 Hours":838.5,"Last 48 Hours":1509.3,"Last Week":4980.69,"Last 28 Days":21416.967},
        {"rank":22,"Member groups":"relax[5]","Last Hour":40,"Last 3 Hours":52,"Last 6 Hours":156,"Last 12 Hours":343.2,"Last 24 Hours":780,"Last 48 Hours":1404,"Last Week":4633.2,"Last 28 Days":19922.76},
        {"rank":23,"Member groups":"relax[73][TR]","Last Hour":39,"Last 3 Hours":50.7,"Last 6 Hours":141.96,"Last 12 Hours":312.312,"Last 24 Hours":709.8,"Last 48 Hours":1277.64,"Last Week":4216.212,"Last 28 Days":18129.7116},
        {"rank":24,"Member groups":"country[BY]","Last Hour":35,"Last 3 Hours":45.5,"Last 6 Hours":136.5,"Last 12 Hours":300.3,"Last 24 Hours":682.5,"Last 48 Hours":1228.5,"Last Week":4054.05,"Last 28 Days":17432.415},
        {"rank":25,"Member groups":"relax[101]","Last Hour":30,"Last 3 Hours":39,"Last 6 Hours":105.3,"Last 12 Hours":231.66,"Last 24 Hours":526.5,"Last 48 Hours":947.7,"Last Week":3127.41,"Last 28 Days":13447.863}],

      productFields: [
        {key: 'rank'},
        {key: 'memberGroups'},
        {key: 'uniqueProducts'},
      ],
    }
    },
  created(){

  },
  computed: {
      productDatasets() {
      var currentStatus = this.status;
      var p=[];
        this.products.forEach(function(product) {
          p.push(product[currentStatus]);
        });
        return p;
      },
      productLabels(){
        var p=[];
        this.products.forEach(function(product) {
          p.push( product["Member groups"]+"  "+ product["rank"]);
        });
        return p;
      }
    },

}
</script>