<template>
  <CCard class="active-players">
    <CCardBody>
      <CRow>
        <CCol sm="6" lg="6">
          <h4  class="card-title mb-3">Turnover </h4>
          <CDataTable
              class="zq--table--dashboard zq--table"
              hover
              :items="products"
              :fields="productFields"
              head-color="light"
              no-sorting
          >
            <template #rank-header>
              <div>Top 25 Member Groups</div>
            </template>
            <td slot="memberGroups" slot-scope="{item}">
              <div>
                {{item["Member Groups"]}}
              </div>
            </td>
            <td slot="turnover" slot-scope="{item}">
              <div>
                {{item[status]}}
              </div>
            </td>
          </CDataTable>
        </CCol>
        <CCol sm="6" lg="6">

          <CChartHorizontalBar
              style="height:500px"
              type='horizontalBar'
              :labels="productLabels"
              :datasets=" [{
                label: 'Turnover',
                data: productDatasets,
                backgroundColor: '#007dfb',
                
                }]"

              :options="{
                maintainAspectRatio: false ,
                scales: {
                yAxes: [{
                 gridLines:{
                        drawBorder: false,
                        display:false,
                      },
                  },
                ],
                XAxes: [{
                ticks: {
                        min:0,
                        max:1400,
                  }
            }]
        }}"
          ></CChartHorizontalBar>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>

</template>

<script>
import {CChartHorizontalBar} from '@coreui/vue-chartjs'
export default {
  name: "turnover",
  components:{
    CChartHorizontalBar
  },
  props: { status: String,},
  data(){
    return{
      products:[{"rank":1,"Member Groups":"CL","Last Hour":3300,"Last 3 Hours":9900,"Last 6 Hours":29700,"Last 12 Hours":89100,"Last 24 Hours":148500,"Last 48 Hours":267300,"Last Week":1336500,"Last 28 Days":5880600},
        {"rank":2,"Member Groups":"relax[317]","Last Hour":1100,"Last 3 Hours":3300,"Last 6 Hours":9900,"Last 12 Hours":29700,"Last 24 Hours":49500,"Last 48 Hours":89100,"Last Week":445500,"Last 28 Days":1960200},
        {"rank":3,"Member Groups":"operator[random]","Last Hour":1095,"Last 3 Hours":3285,"Last 6 Hours":9855,"Last 12 Hours":29565,"Last 24 Hours":49275,"Last 48 Hours":88695,"Last Week":443475,"Last 28 Days":1951290},
        {"rank":4,"Member Groups":"environment[LUX]","Last Hour":840,"Last 3 Hours":2520,"Last 6 Hours":7560,"Last 12 Hours":22680,"Last 24 Hours":37800,"Last 48 Hours":68040,"Last Week":340200,"Last 28 Days":1496880},
        {"rank":5,"Member Groups":"brandId[20]","Last Hour":605,"Last 3 Hours":1815,"Last 6 Hours":5445,"Last 12 Hours":16335,"Last 24 Hours":27225,"Last 48 Hours":49005,"Last Week":245025,"Last 28 Days":1078110},
        {"rank":6,"Member Groups":"country[CH]","Last Hour":599,"Last 3 Hours":1797,"Last 6 Hours":5391,"Last 12 Hours":16173,"Last 24 Hours":26955,"Last 48 Hours":48519,"Last Week":242595,"Last 28 Days":1067418},
        {"rank":7,"Member Groups":"parentPartner","Last Hour":543,"Last 3 Hours":1629,"Last 6 Hours":4887,"Last 12 Hours":14661,"Last 24 Hours":24435,"Last 48 Hours":43983,"Last Week":219915,"Last 28 Days":967626},
        {"rank":8,"Member Groups":"free_player","Last Hour":534,"Last 3 Hours":1602,"Last 6 Hours":4806,"Last 12 Hours":14418,"Last 24 Hours":24030,"Last 48 Hours":43254,"Last Week":216270,"Last 28 Days":951588},
        {"rank":9,"Member Groups":"demo","Last Hour":123,"Last 3 Hours":369,"Last 6 Hours":1107,"Last 12 Hours":3321,"Last 24 Hours":5535,"Last 48 Hours":9963,"Last Week":49815,"Last 28 Days":219186},
        {"rank":10,"Member Groups":"relax[150]","Last Hour":102,"Last 3 Hours":306,"Last 6 Hours":918,"Last 12 Hours":2754,"Last 24 Hours":4590,"Last 48 Hours":8262,"Last Week":41310,"Last 28 Days":181764},
        {"rank":11,"Member Groups":"games","Last Hour":90,"Last 3 Hours":270,"Last 6 Hours":810,"Last 12 Hours":2430,"Last 24 Hours":4050,"Last 48 Hours":7290,"Last Week":36450,"Last 28 Days":160380},
        {"rank":12,"Member Groups":"country[RU]","Last Hour":85,"Last 3 Hours":255,"Last 6 Hours":765,"Last 12 Hours":2295,"Last 24 Hours":3825,"Last 48 Hours":6885,"Last Week":34425,"Last 28 Days":151470},
        {"rank":13,"Member Groups":"relax[240]","Last Hour":84,"Last 3 Hours":252,"Last 6 Hours":756,"Last 12 Hours":2268,"Last 24 Hours":3780,"Last 48 Hours":6804,"Last Week":34020,"Last 28 Days":149688},
        {"rank":14,"Member Groups":"relax[123]","Last Hour":72,"Last 3 Hours":216,"Last 6 Hours":648,"Last 12 Hours":1944,"Last 24 Hours":3240,"Last 48 Hours":5832,"Last Week":29160,"Last 28 Days":128304},
        {"rank":15,"Member Groups":"country[FI]","Last Hour":60,"Last 3 Hours":180,"Last 6 Hours":540,"Last 12 Hours":1620,"Last 24 Hours":2700,"Last 48 Hours":4860,"Last Week":24300,"Last 28 Days":106920},
        {"rank":16,"Member Groups":"relax[110]","Last Hour":55,"Last 3 Hours":165,"Last 6 Hours":495,"Last 12 Hours":1485,"Last 24 Hours":2475,"Last 48 Hours":4455,"Last Week":22275,"Last 28 Days":98010},
        {"rank":17,"Member Groups":"country[SE]","Last Hour":50,"Last 3 Hours":150,"Last 6 Hours":450,"Last 12 Hours":1350,"Last 24 Hours":2250,"Last 48 Hours":4050,"Last Week":20250,"Last 28 Days":89100},
        {"rank":18,"Member Groups":"relax[11]","Last Hour":48,"Last 3 Hours":144,"Last 6 Hours":432,"Last 12 Hours":1296,"Last 24 Hours":2160,"Last 48 Hours":3888,"Last Week":19440,"Last 28 Days":85536},
        {"rank":19,"Member Groups":"relax[222]","Last Hour":47,"Last 3 Hours":141,"Last 6 Hours":423,"Last 12 Hours":1269,"Last 24 Hours":2115,"Last 48 Hours":3807,"Last Week":19035,"Last 28 Days":83754},
        {"rank":20,"Member Groups":"country[NL]","Last Hour":40,"Last 3 Hours":120,"Last 6 Hours":360,"Last 12 Hours":1080,"Last 24 Hours":1800,"Last 48 Hours":3240,"Last Week":16200,"Last 28 Days":71280},
        {"rank":21,"Member Groups":"country[MT]","Last Hour":32,"Last 3 Hours":96,"Last 6 Hours":288,"Last 12 Hours":864,"Last 24 Hours":1440,"Last 48 Hours":2592,"Last Week":12960,"Last 28 Days":57024},
        {"rank":22,"Member Groups":"relax[5]","Last Hour":30,"Last 3 Hours":90,"Last 6 Hours":270,"Last 12 Hours":810,"Last 24 Hours":1350,"Last 48 Hours":2430,"Last Week":12150,"Last 28 Days":53460},
        {"rank":23,"Member Groups":"relax[73][TR]","Last Hour":28,"Last 3 Hours":84,"Last 6 Hours":252,"Last 12 Hours":756,"Last 24 Hours":1260,"Last 48 Hours":2268,"Last Week":11340,"Last 28 Days":49896},
        {"rank":24,"Member Groups":"country[BY]","Last Hour":27,"Last 3 Hours":81,"Last 6 Hours":243,"Last 12 Hours":729,"Last 24 Hours":1215,"Last 48 Hours":2187,"Last Week":10935,"Last 28 Days":48114},
        {"rank":25,"Member Groups":"relax[101]","Last Hour":25,"Last 3 Hours":75,"Last 6 Hours":225,"Last 12 Hours":675,"Last 24 Hours":1125,"Last 48 Hours":2025,"Last Week":10125,"Last 28 Days":44550}],
      productFields: [
        {key: 'rank'},
        {key: 'memberGroups'},
        {key: 'turnover'},
      ],
    }
    },
  created(){

  },
  computed: {
    productDatasets() {
      var currentStatus = this.status;
      var p=[];
      this.products.forEach(function(product) {
        p.push(product[currentStatus]);
      });
      return p;
    },

    productLabels(){
      var p=[];
      this.products.forEach(function(product) {
        p.push( product["Member Groups"]+"  "+ product["rank"]);
      });
      return p;
    }
  },
}
</script>
