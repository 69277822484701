<template>
  <CRow>
    <CCol sm="12" lg="12" class="d-md-block">
      <CCard class="zq-card-table zq-card-table--with-header">
        <CCardHeader style="font-size: 21px;">
          Live Achievements triggered
        </CCardHeader>
        <CCardBody v-if="activeAchievements.length">
          <CDataTable
            class="zq--table"
            hover
            :items="activeAchievements"
            :fields="tableFields"
            head-color="light"
              no-sorting
          >
            <template #icons="{ item }">
              <td class="text-center">
                <div class="c-avatar">
                  <img :src="getIcon(item.icon)" class="c-avatar-img size30" alt="">
                </div>
              </td>
            </template>
            <template #achievementName="{ item }">
              <td class="text-center">
                {{ item.name }}
              </td>
            </template>
            <template #achievementId="{ item }">
              <td class="text-center">
                <a href="#">{{ item.id }}</a>
              </td>
            </template>
            <template #scheduleType="{ item }">
              <td class="text-center">
                {{ item.scheduling.scheduleType }}
              </td>
            </template>
            <template #startDate="{ item }">
              <td class="text-center">
                {{ dateFormate(item.scheduling.startDate) }}
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { dateFormate } from '@/utils/dateFormate';

export default {
  name: "AchievementTable",
  props: {
    activeAchievements: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      tableFields: ['icons', 'achievementName', 'achievementId', 'scheduleType', 'startDate'],
      defaultIcons: [
        'img/avatars/Castle.png',
        'img/avatars/floatingChar.png',
        'img/avatars/Castle.png',
        'img/avatars/megaChar.png',
        'img/avatars/megaChar.png',
        'img/avatars/flyingShip.png',
        'img/avatars/4.jpg',
        'img/avatars/floatingChar.png',
        'img/avatars/flyingShip.png',
        'img/avatars/4.jpg'
      ],
    }
  },
  methods: {
    dateFormate(val) {
      return dateFormate(val);
    },
    getIcon(icon) {
      return icon ?? this.defaultIcons[Math.floor(Math.random() * (this.defaultIcons.length))];
    }
  },
}
</script>

<style scoped>

</style>