<template>
<CRow>
  <CCol sm="12" lg="12" class="d-md-block">
    <CCard class="zq-card-table zq-card-table--with-header">
      <CCardHeader style="font-size: 21px;">
        Live Competitions
      </CCardHeader>
      <CCardBody v-if="activeCompetitions.length">
        <CDataTable
          class="zq--table"
          hover
          :items="activeCompetitions"
          :fields="tableFields"
          head-color="light"
          no-sorting
        >
          <template #id="{ item }">
            <td class="nth-id">
              <div class="link" @click="showPreview(item)">
                {{ item.id }}
              </div>
            </td>
          </template>
          <template #leaderboard="{ item }">
            <td>
              <div class="link" @click="showPreview(item)">
                Go to leaderboard
              </div>
            </td>
          </template>
          <template #scheduledStartDate="{ item }">
            <td>
              {{ dateFormate(item.scheduledStartDate) }}
            </td>
          </template>
          <template #scheduledEndDate="{ item }">
            <td>
              {{ dateFormate(item.scheduledEndDate) }}
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </CCol>
</CRow>
</template>

<script>
import { dateFormate } from '@/utils/dateFormate';

export default {
  name: "CompetitionsTable",
  props: {
    activeCompetitions: {
      type: Array,
      default() {
        return [];
      }
    },
  },
  data() {
    return {
      selected: 'Month',
      tableItems: [],
      tableFields: [ 'id', 'name', 'scheduledStartDate', 'scheduledEndDate', 'leaderboard'],
    }
  },
  methods: {
    showPreview(item) {
      this.$router.push({
        name: 'PreviewCompetition',
        params: {
          id: item.id,
          name: item.name,
        },
      });
    },
    dateFormate(val) {
      return dateFormate(val);
    },
  },
}
</script>

<style scoped>

</style>