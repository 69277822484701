<template>
  <div class="content">

    <div>
      <ClSelect
          class="text-right m-0 mr-1 mb-2 d-xl-none text-capitalize"
          v-if="statuses.length > 0"
          :value-prop="statuses[0]"
          :options="statuses"
          @checkValue="checkStatus"
          />

      <div
          v-if="statuses.length"
          class="ml-0 mr-1 mb-2 text-capitalize d-xl-flex d-none align-items-center">
        <span class="mr-3"> Show: </span>
        <CButton
            variant="outline"
            color="dark"
            class="zq--table-header-statuses-btn"
            :class="{'active-status': status === currentStatus}"
            @click="checkStatus({value:status})"
            v-for="status in statuses"
            :key="status"
        >{{ status }}
        </CButton>
      </div>
    </div>
    <ActivePlayers :status="currentStatus"></ActivePlayers>
    <Turnover :status="currentStatus"></Turnover>
    <Rounds :status="currentStatus"></Rounds>
    <ProfitLoss :status="currentStatus"></ProfitLoss>
    <!--<CCard>
      <CCardBody>
        <CRow>
          <CCol sm="5">
            <h4 id="traffic" class="card-title mb-0">Top 10 Games by win/loss</h4>

          </CCol></CRow>
        <CRow>
          <CCol sm="12" lg="12">

            <CChartHorizontalBar
             style="height:300px"
             :datasets="[
              {
                data: [-30, -22, 34, -46, 58, -70, 46, -23, 45, 78, 34, 12],
                backgroundColor: '#01A3BB',
                label: 'Game Provider',
              },
              {
                data: [-20, -40, 23, -45, 78, -34, 12, -22,20, 34, 46, 58],
                backgroundColor: '#3BB54C',
                label: 'Operator',
              }
            ]"
            labels="months"
            :options="{ maintainAspectRatio: false ,
              scales: {
                    yAxes: [{
                        min: 40,
                        ticks: {
                            // Include a k sign in the ticks
                            callback: function(value, index, values) {
                                return value +'k';
                            }
                        }
                    }]
                }}"
            ></CChartHorizontalBar>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>-->
  </div>
</template>

<script>

import { CChartLine ,CChartHorizontalBar} from '@coreui/vue-chartjs'
import ActivePlayers from "./ActivePlayers";
import ClSelect from '@/shared/components/formComponents/ClSelect';
import Turnover from "./Turnover";
import Rounds from "./Rounds";
import ProfitLoss from "./ProfitLoss";
export default {
  name: "Products",
  data() {
    return {
      statuses:['Last Hour','Last 3 Hours','Last 6 Hours','Last 12 Hours','Last 24 Hours','Last 48 Hours','Last Week','Last 28 Days'],
      currentStatus:'Last Hour',

      }
  },
  components:{
    ProfitLoss,
    CChartLine,
    CChartHorizontalBar,
    ClSelect,
    ActivePlayers,
    Turnover,
    Rounds
  },
  computed: {

  },
  created(){

  },
  methods:{
    checkStatus(value){
      this.currentStatus = value['value'];
      this.$emit('checkStatus', value);
    }
  }
}
</script>

<style scoped>

</style>