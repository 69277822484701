<template>
  <CCard class="active-players">
    <CCardBody>
      <CRow>
        <CCol sm="6" lg="6">
          <h4  class="card-title mb-3">Rounds </h4>
          <CDataTable
              class="zq--table--dashboard zq--table"
              hover
              :items="products"
              :fields="productFields"
              head-color="light"
              no-sorting
          >
            <template #rank-header>
              <div>Top 25 Members</div>
            </template>
            <td slot="members" slot-scope="{item}">
              <router-link :to="{name: `PreviewMember`, params: {id: item[`Members`]} }">
                {{item["Members"]}}
              </router-link>
            </td>
            <td slot="rounds" slot-scope="{item}">
              <div>
                {{item[status]}}
              </div>
            </td>
          </CDataTable>
        </CCol>
        <CCol sm="6" lg="6">

          <CChartHorizontalBar
              style="height:500px"
              type='horizontalBar'
              :labels="productLabels"
              :datasets=" [{
                label: 'Rounds',
                data: productDatasets,
                    backgroundColor: '#f48f3b',
                }]"

              :options="{
                maintainAspectRatio: false ,
                scales: {
                yAxes: [{
                 gridLines:{
                        drawBorder: false,
                        display:false,
                      },
                  },
                ],
                XAxes: [{
                ticks: {
                        min:0,
                        max:1400,
                  }
            }]
        }}"
          ></CChartHorizontalBar>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>

</template>

<script>
import {CChartHorizontalBar} from '@coreui/vue-chartjs'
export default {
  name: "rounds",
  components:{
    CChartHorizontalBar
  },
  props: { status: String,},
  data(){
    return{
      products:[{"rank":1,"Members":"-ehuzncBnBVJ8S2bVkdD","Last Hour":55,"Last 3 Hours":71.5,"Last 6 Hours":214.5,"Last 12 Hours":471.9,"Last 24 Hours":1179.75,"Last 48 Hours":2595.45,"Last Week":8564.985,"Last 28 Days":36829.4355},
        {"rank":2,"Members":"ouhdzncBnBVJ8S2b7Ufw","Last Hour":48,"Last 3 Hours":62.4,"Last 6 Hours":174.72,"Last 12 Hours":384.384,"Last 24 Hours":873.6,"Last 48 Hours":1572.48,"Last Week":5189.184,"Last 28 Days":22313.4912},
        {"rank":3,"Members":"mehczncBnBVJ8S2bREcG","Last Hour":37,"Last 3 Hours":48.1,"Last 6 Hours":144.3,"Last 12 Hours":317.46,"Last 24 Hours":721.5,"Last 48 Hours":1298.7,"Last Week":4285.71,"Last 28 Days":18428.553},
        {"rank":4,"Members":"4ug0zncBnBVJ8S2bmEYN","Last Hour":35,"Last 3 Hours":45.5,"Last 6 Hours":136.5,"Last 12 Hours":300.3,"Last 24 Hours":682.5,"Last 48 Hours":1228.5,"Last Week":4054.05,"Last 28 Days":17432.415},
        {"rank":5,"Members":"0egxzncBnBVJ8S2bsUac","Last Hour":34,"Last 3 Hours":44.2,"Last 6 Hours":132.6,"Last 12 Hours":291.72,"Last 24 Hours":663,"Last 48 Hours":1193.4,"Last Week":3938.22,"Last 28 Days":16934.346},
        {"rank":6,"Members":"w-gwzncBnBVJ8S2bB0bm","Last Hour":33,"Last 3 Hours":42.9,"Last 6 Hours":128.7,"Last 12 Hours":283.14,"Last 24 Hours":643.5,"Last 48 Hours":1158.3,"Last Week":3822.39,"Last 28 Days":16436.277},
        {"rank":7,"Members":"DOgOzncBnBVJ8S2bJEZd","Last Hour":30,"Last 3 Hours":39,"Last 6 Hours":117,"Last 12 Hours":257.4,"Last 24 Hours":585,"Last 48 Hours":1053,"Last Week":3474.9,"Last 28 Days":14942.07},
        {"rank":8,"Members":"bW5cjHcBZJqtaNvN14NI","Last Hour":28,"Last 3 Hours":36.4,"Last 6 Hours":109.2,"Last 12 Hours":240.24,"Last 24 Hours":546,"Last 48 Hours":982.8,"Last Week":3243.24,"Last 28 Days":13945.932},
        {"rank":9,"Members":"Y25ajHcBZJqtaNvNHIMO","Last Hour":22,"Last 3 Hours":28.6,"Last 6 Hours":80.08,"Last 12 Hours":176.176,"Last 24 Hours":400.4,"Last 48 Hours":720.72,"Last Week":2378.376,"Last 28 Days":10227.0168},
        {"rank":10,"Members":"X25ZjHcBZJqtaNvNAIPV","Last Hour":20,"Last 3 Hours":26,"Last 6 Hours":78,"Last 12 Hours":171.6,"Last 24 Hours":390,"Last 48 Hours":702,"Last Week":2316.6,"Last 28 Days":9961.38},
        {"rank":11,"Members":"UW5VjHcBZJqtaNvNyYNw","Last Hour":16,"Last 3 Hours":20.8,"Last 6 Hours":62.4,"Last 12 Hours":137.28,"Last 24 Hours":312,"Last 48 Hours":561.6,"Last Week":1853.28,"Last 28 Days":7969.104},
        {"rank":12,"Members":"8W48jHcBZJqtaNvNtIKp","Last Hour":12,"Last 3 Hours":15.6,"Last 6 Hours":46.8,"Last 12 Hours":102.96,"Last 24 Hours":234,"Last 48 Hours":421.2,"Last Week":1389.96,"Last 28 Days":5976.828},
        {"rank":13,"Members":"3242jHcBZJqtaNvNwoK_","Last Hour":11,"Last 3 Hours":14.3,"Last 6 Hours":42.9,"Last 12 Hours":94.38,"Last 24 Hours":214.5,"Last 48 Hours":386.1,"Last Week":1274.13,"Last 28 Days":5478.759},
        {"rank":14,"Members":"2W41jHcBZJqtaNvNHYIs","Last Hour":10,"Last 3 Hours":13,"Last 6 Hours":39,"Last 12 Hours":85.8,"Last 24 Hours":195,"Last 48 Hours":351,"Last Week":1158.3,"Last 28 Days":4980.69},
        {"rank":15,"Members":"1G40jHcBZJqtaNvNdoLl","Last Hour":8,"Last 3 Hours":10.4,"Last 6 Hours":31.2,"Last 12 Hours":68.64,"Last 24 Hours":156,"Last 48 Hours":280.8,"Last Week":926.64,"Last 28 Days":3984.552},
        {"rank":16,"Members":"j24mjHcBZJqtaNvNwIIj","Last Hour":8,"Last 3 Hours":10.4,"Last 6 Hours":31.2,"Last 12 Hours":68.64,"Last 24 Hours":156,"Last 48 Hours":280.8,"Last Week":926.64,"Last 28 Days":3984.552},
        {"rank":17,"Members":"nm3nXXcBZJqtaNvN9NYW","Last Hour":8,"Last 3 Hours":10.4,"Last 6 Hours":29.12,"Last 12 Hours":64.064,"Last 24 Hours":145.6,"Last 48 Hours":262.08,"Last Week":864.864,"Last 28 Days":3718.9152},
        {"rank":18,"Members":"Pm2VXXcBZJqtaNvNLNUT","Last Hour":6,"Last 3 Hours":7.8,"Last 6 Hours":23.4,"Last 12 Hours":51.48,"Last 24 Hours":117,"Last 48 Hours":210.6,"Last Week":694.98,"Last 28 Days":2988.414},
        {"rank":19,"Members":"LW2PXXcBZJqtaNvNhtVH","Last Hour":5,"Last 3 Hours":6.5,"Last 6 Hours":19.5,"Last 12 Hours":42.9,"Last 24 Hours":97.5,"Last 48 Hours":175.5,"Last Week":579.15,"Last 28 Days":2490.345},
        {"rank":20,"Members":"zm0uT3cBZJqtaNvNPqK1","Last Hour":5,"Last 3 Hours":6.5,"Last 6 Hours":18.2,"Last 12 Hours":40.04,"Last 24 Hours":91,"Last 48 Hours":163.8,"Last Week":540.54,"Last 28 Days":2324.322},
        {"rank":21,"Members":"Bm0bSXcBZJqtaNvNvYk_","Last Hour":4,"Last 3 Hours":5.2,"Last 6 Hours":15.6,"Last 12 Hours":34.32,"Last 24 Hours":78,"Last 48 Hours":140.4,"Last Week":463.32,"Last 28 Days":1992.276},
        {"rank":22,"Members":"Ym3xSHcBZJqtaNvNWIgM","Last Hour":3,"Last 3 Hours":3.9,"Last 6 Hours":11.7,"Last 12 Hours":25.74,"Last 24 Hours":58.5,"Last 48 Hours":105.3,"Last Week":347.49,"Last 28 Days":1494.207},
        {"rank":23,"Members":"Wm3wSHcBZJqtaNvNH4gp","Last Hour":3,"Last 3 Hours":3.9,"Last 6 Hours":10.92,"Last 12 Hours":24.024,"Last 24 Hours":54.6,"Last 48 Hours":98.28,"Last Week":324.324,"Last 28 Days":1394.5932},
        {"rank":24,"Members":"PG3qSHcBZJqtaNvNUojP","Last Hour":2,"Last 3 Hours":2.6,"Last 6 Hours":7.8,"Last 12 Hours":17.16,"Last 24 Hours":39,"Last 48 Hours":70.2,"Last Week":231.66,"Last 28 Days":996.138},
        {"rank":25,"Members":"N23oSHcBZJqtaNvNvoj8","Last Hour":2,"Last 3 Hours":2.6,"Last 6 Hours":7.02,"Last 12 Hours":15.444,"Last 24 Hours":35.1,"Last 48 Hours":63.18,"Last Week":208.494,"Last 28 Days":896.5242}],

      productFields: [{key: 'rank'},
        {key: 'members'},
        {key: 'rounds'},
      ],
    }
    },
  created(){

  },
  computed: {
    productDatasets() {
      var currentStatus = this.status;
      var p=[];
      this.products.forEach(function(product) {
        p.push(product[currentStatus]);
      });
      return p;
    },

    productLabels(){
      var p=[];
      this.products.forEach(function(product) {
        p.push( product["Members"]+"  "+ product["rank"]);
      });
      return p;
    }
  },


}
</script>
