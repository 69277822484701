var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',{staticClass:"active-players"},[_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"sm":"6","lg":"6"}},[_c('h4',{staticClass:"card-title mb-3"},[_vm._v("Turnover ")]),_c('CDataTable',{staticClass:"zq--table--dashboard zq--table",attrs:{"hover":"","items":_vm.products,"fields":_vm.productFields,"head-color":"light","no-sorting":""},scopedSlots:_vm._u([{key:"rank-header",fn:function(){return [_c('div',[_vm._v("Top 25 Members")])]},proxy:true},{key:"members",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('router-link',{attrs:{"to":{name: "PreviewMember", params: {id: item["Members"]} }}},[_vm._v(" "+_vm._s(item["Members"])+" ")])],1)}},{key:"turnover",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item[_vm.status])+" ")])}}])})],1),_c('CCol',{attrs:{"sm":"6","lg":"6"}},[_c('CChartHorizontalBar',{staticStyle:{"height":"500px"},attrs:{"type":"horizontalBar","labels":_vm.productLabels,"datasets":[{
              label: 'Turnover',
              data: _vm.productDatasets,
              backgroundColor: '#007dfb',
              
              }],"options":{
              maintainAspectRatio: false ,
              scales: {
              yAxes: [{
               gridLines:{
                      drawBorder: false,
                      display:false,
                    },
                } ],
              XAxes: [{
              ticks: {
                      min:0,
                      max:1400,
                }
          }]
      }}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }