<template>
  <h3 class="p-4">Achievements Tab</h3>
</template>

<script>
export default {
  name: "Achievements"
}
</script>

<style scoped>

</style>