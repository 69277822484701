<template>
  <CCard class="active-players">
      <CCardBody>
        <CRow>
          <CCol sm="6" lg="6">
            <h4 class="card-title mb-3">P&L</h4>
            <CDataTable
                class="zq--table--dashboard zq--table"
                hover
                :items="products"
                :fields="productFields"
                head-color="light"
                no-sorting
            >
              <template #rank-header>
                <div>Top 25 Games</div>
              </template>
              <template #pNL-header>
                <div>P&L</div>
              </template>
              <td slot="members" slot-scope="{item}">
                <router-link :to="{name: `PreviewMember`, params: {id: item[`Members`]} }">
                  {{item["Members"]}}
                </router-link>
              </td>
              <td slot="pNL" slot-scope="{item}">
                  {{item[status]}}
              </td>
            </CDataTable>
          </CCol>

          <CCol sm="6" lg="6">

            <CChartLine
                style="height:400px"
                :labels=productLabels
                :datasets="[
                 {
                    data: productDatasets,
                    backgroundColor: '#ACDBAF',
                    label: 'P&L',
                    borderColor:'#0f9311 ',
                    lineTension:0
                  }
                ]"

                :options="{ maintainAspectRatio: false ,
                   scales: {
                    yAxes: [{
                    min: 40

            }],
        }}"
            ></CChartLine>
          </CCol>
        </CRow>
      </CCardBody>
  </CCard>

</template>

<script>
import { CChartLine } from '@coreui/vue-chartjs'
export default {
  name: "ProfitLoss",
  components:{
    CChartLine
  },
  props: { status: String,},
  data(){
    return{
      products:[{"rank":1,"Members":"-ehuzncBnBVJ8S2bVkdD","Last Hour":532,"Last 3 Hours":1596,"Last 6 Hours":4788,"Last 12 Hours":14364,"Last 24 Hours":23940,"Last 48 Hours":43092,"Last Week":215460,"Last 28 Days":948024},
        {"rank":2,"Members":"ouhdzncBnBVJ8S2b7Ufw","Last Hour":485,"Last 3 Hours":1455,"Last 6 Hours":4365,"Last 12 Hours":13095,"Last 24 Hours":21825,"Last 48 Hours":39285,"Last Week":196425,"Last 28 Days":864270},
        {"rank":3,"Members":"mehczncBnBVJ8S2bREcG","Last Hour":475,"Last 3 Hours":1425,"Last 6 Hours":4275,"Last 12 Hours":12825,"Last 24 Hours":21375,"Last 48 Hours":38475,"Last Week":192375,"Last 28 Days":846450},
        {"rank":4,"Members":"4ug0zncBnBVJ8S2bmEYN","Last Hour":452,"Last 3 Hours":1356,"Last 6 Hours":4068,"Last 12 Hours":12204,"Last 24 Hours":20340,"Last 48 Hours":36612,"Last Week":183060,"Last 28 Days":805464},
        {"rank":5,"Members":"0egxzncBnBVJ8S2bsUac","Last Hour":432,"Last 3 Hours":1296,"Last 6 Hours":3888,"Last 12 Hours":11664,"Last 24 Hours":19440,"Last 48 Hours":34992,"Last Week":174960,"Last 28 Days":769824},
        {"rank":6,"Members":"w-gwzncBnBVJ8S2bB0bm","Last Hour":399,"Last 3 Hours":1197,"Last 6 Hours":3591,"Last 12 Hours":10773,"Last 24 Hours":17955,"Last 48 Hours":32319,"Last Week":161595,"Last 28 Days":711018},
        {"rank":7,"Members":"DOgOzncBnBVJ8S2bJEZd","Last Hour":382,"Last 3 Hours":1146,"Last 6 Hours":3438,"Last 12 Hours":10314,"Last 24 Hours":17190,"Last 48 Hours":30942,"Last Week":154710,"Last 28 Days":680724},
        {"rank":8,"Members":"bW5cjHcBZJqtaNvN14NI","Last Hour":341,"Last 3 Hours":1023,"Last 6 Hours":3069,"Last 12 Hours":9207,"Last 24 Hours":15345,"Last 48 Hours":27621,"Last Week":138105,"Last 28 Days":607662},
        {"rank":9,"Members":"Y25ajHcBZJqtaNvNHIMO","Last Hour":312,"Last 3 Hours":936,"Last 6 Hours":2808,"Last 12 Hours":8424,"Last 24 Hours":14040,"Last 48 Hours":25272,"Last Week":126360,"Last 28 Days":555984},
        {"rank":10,"Members":"X25ZjHcBZJqtaNvNAIPV","Last Hour":298,"Last 3 Hours":894,"Last 6 Hours":2682,"Last 12 Hours":8046,"Last 24 Hours":13410,"Last 48 Hours":24138,"Last Week":120690,"Last 28 Days":531036},
        {"rank":11,"Members":"UW5VjHcBZJqtaNvNyYNw","Last Hour":280,"Last 3 Hours":840,"Last 6 Hours":2520,"Last 12 Hours":7560,"Last 24 Hours":12600,"Last 48 Hours":22680,"Last Week":113400,"Last 28 Days":498960},
        {"rank":12,"Members":"8W48jHcBZJqtaNvNtIKp","Last Hour":277,"Last 3 Hours":831,"Last 6 Hours":2493,"Last 12 Hours":7479,"Last 24 Hours":12465,"Last 48 Hours":22437,"Last Week":112185,"Last 28 Days":493614},
        {"rank":13,"Members":"8u63_XMB3XISahIGMvW5","Last Hour":260,"Last 3 Hours":780,"Last 6 Hours":2340,"Last 12 Hours":7020,"Last 24 Hours":11700,"Last 48 Hours":21060,"Last Week":105300,"Last 28 Days":463320},
        {"rank":14,"Members":"3242jHcBZJqtaNvNwoK_","Last Hour":255,"Last 3 Hours":765,"Last 6 Hours":2295,"Last 12 Hours":6885,"Last 24 Hours":11475,"Last 48 Hours":20655,"Last Week":103275,"Last 28 Days":454410},
        {"rank":15,"Members":"2W41jHcBZJqtaNvNHYIs","Last Hour":230,"Last 3 Hours":690,"Last 6 Hours":2070,"Last 12 Hours":6210,"Last 24 Hours":10350,"Last 48 Hours":18630,"Last Week":93150,"Last 28 Days":409860},
        {"rank":16,"Members":"1G40jHcBZJqtaNvNdoLl","Last Hour":222,"Last 3 Hours":666,"Last 6 Hours":1998,"Last 12 Hours":5994,"Last 24 Hours":9990,"Last 48 Hours":17982,"Last Week":89910,"Last 28 Days":395604},
        {"rank":17,"Members":"j24mjHcBZJqtaNvNwIIj","Last Hour":190,"Last 3 Hours":570,"Last 6 Hours":1710,"Last 12 Hours":5130,"Last 24 Hours":8550,"Last 48 Hours":15390,"Last Week":76950,"Last 28 Days":338580},
        {"rank":18,"Members":"nm3nXXcBZJqtaNvN9NYW","Last Hour":178,"Last 3 Hours":534,"Last 6 Hours":1602,"Last 12 Hours":4806,"Last 24 Hours":8010,"Last 48 Hours":14418,"Last Week":72090,"Last 28 Days":317196},
        {"rank":19,"Members":"Pm2VXXcBZJqtaNvNLNUT","Last Hour":163,"Last 3 Hours":489,"Last 6 Hours":1467,"Last 12 Hours":4401,"Last 24 Hours":7335,"Last 48 Hours":13203,"Last Week":66015,"Last 28 Days":290466},
        {"rank":20,"Members":"LW2PXXcBZJqtaNvNhtVH","Last Hour":132,"Last 3 Hours":396,"Last 6 Hours":1188,"Last 12 Hours":3564,"Last 24 Hours":5940,"Last 48 Hours":10692,"Last Week":53460,"Last 28 Days":235224},
        {"rank":21,"Members":"zm0uT3cBZJqtaNvNPqK1","Last Hour":112,"Last 3 Hours":336,"Last 6 Hours":1008,"Last 12 Hours":3024,"Last 24 Hours":5040,"Last 48 Hours":9072,"Last Week":45360,"Last 28 Days":199584},
        {"rank":22,"Members":"Bm0bSXcBZJqtaNvNvYk_","Last Hour":111,"Last 3 Hours":333,"Last 6 Hours":999,"Last 12 Hours":2997,"Last 24 Hours":4995,"Last 48 Hours":8991,"Last Week":44955,"Last 28 Days":197802},
        {"rank":23,"Members":"Ym3xSHcBZJqtaNvNWIgM","Last Hour":98,"Last 3 Hours":294,"Last 6 Hours":882,"Last 12 Hours":2646,"Last 24 Hours":4410,"Last 48 Hours":7938,"Last Week":39690,"Last 28 Days":174636},
        {"rank":24,"Members":"Wm3wSHcBZJqtaNvNH4gp","Last Hour":75,"Last 3 Hours":225,"Last 6 Hours":675,"Last 12 Hours":2025,"Last 24 Hours":3375,"Last 48 Hours":6075,"Last Week":30375,"Last 28 Days":133650},
        {"rank":25,"Members":"N23oSHcBZJqtaNvNvoj8","Last Hour":60,"Last 3 Hours":180,"Last 6 Hours":540,"Last 12 Hours":1620,"Last 24 Hours":2700,"Last 48 Hours":4860,"Last Week":24300,"Last 28 Days":106920}],
      productFields: [
        {key: 'rank'},
        {key: 'members'},
        {key: 'pNL'},
      ],
    }
    },
  created(){

  },
  computed: {
    productDatasets() {
      var currentStatus = this.status;
      var p=[];
      this.products.forEach(function(product) {
        p.push(product[currentStatus]);
      });
      return p;
    },

    productLabels(){
      var p=[];
      this.products.forEach(function(product) {
        p.push( product["Members"]+"  "+ product["rank"]);
      });
      return p;
    }
  },

}
</script>
