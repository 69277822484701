<template>
  <div class="dashboard-page">
    <!--   Header   -->
    <div class="zq-page-title-wrapper d-flex">
        <h3 class="zq-page-title">{{ $t('dashboard.title') }}</h3>
      <IconWithTooltip class="zq--header-tooltip" :text="descriptions.pageTitle"/>
    </div>
    <CTabs class="achievements-tabs zq--tabs">
      <CTab title="Main">
        <Main/>
      </CTab>
<!--      <CTab title="Products">
        <Products/>
      </CTab>
      <CTab title="Members">
        <Members/>
      </CTab>-->
      <!--<CTab title="Achievements">
        <Achievements/>
      </CTab>
      <CTab title="Promotions">
        <Promotions/>
      </CTab>-->
    </CTabs>
  </div>
</template>

<script>
import {dashboard} from '@/config/descriptions/dashboard';
import Main from './details/Main/index';
import Products from './details/Products/index';
import Members from './details/Members/index';
import Promotions from './details/Promotions';
import Achievements from './details/Achievements';
import IconWithTooltip from '@/shared/UI/IconWithTooltip';

export default {
  name: 'dashboard',
  components: {
    Main,
    Products,
    Members,
    Promotions,
    Achievements,
    IconWithTooltip,
  },
  data() {
    return {
      descriptions: {
        ...dashboard
      },
    }
  }
}
</script>

<style lang="scss">
.content {
  background: var(--zq-sub-bg);
  border-top: 1px solid var(--zq-content-border-color);
}
.dashboard-page{
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
