var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{staticClass:"d-md-block",attrs:{"sm":"12","lg":"12"}},[_c('CCard',{staticClass:"zq-card-table zq-card-table--with-header"},[_c('CCardHeader',{staticStyle:{"font-size":"21px"}},[_vm._v(" Live Competitions ")]),(_vm.activeCompetitions.length)?_c('CCardBody',[_c('CDataTable',{staticClass:"zq--table",attrs:{"hover":"","items":_vm.activeCompetitions,"fields":_vm.tableFields,"head-color":"light","no-sorting":""},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"nth-id"},[_c('div',{staticClass:"link",on:{"click":function($event){return _vm.showPreview(item)}}},[_vm._v(" "+_vm._s(item.id)+" ")])])]}},{key:"leaderboard",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"link",on:{"click":function($event){return _vm.showPreview(item)}}},[_vm._v(" Go to leaderboard ")])])]}},{key:"scheduledStartDate",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.dateFormate(item.scheduledStartDate))+" ")])]}},{key:"scheduledEndDate",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.dateFormate(item.scheduledEndDate))+" ")])]}}],null,false,2495295320)})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }