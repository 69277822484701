var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',{staticClass:"active-players"},[_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"sm":"6","lg":"6"}},[_c('h4',{staticClass:"card-title mb-3"},[_vm._v("P&L")]),_c('CDataTable',{staticClass:"zq--table--dashboard zq--table",attrs:{"hover":"","items":_vm.products,"fields":_vm.productFields,"head-color":"light","no-sorting":""},scopedSlots:_vm._u([{key:"rank-header",fn:function(){return [_c('div',[_vm._v("Top 25 Games")])]},proxy:true},{key:"pNL-header",fn:function(){return [_c('div',[_vm._v("P&L")])]},proxy:true},{key:"productName",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('div',[_vm._v(" "+_vm._s(item["product name"])+" ")])])}},{key:"pNL",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('div',[_vm._v(" "+_vm._s(item[_vm.status])+" ")])])}}])})],1),_c('CCol',{attrs:{"sm":"6","lg":"6"}},[_c('CChartLine',{staticStyle:{"height":"400px"},attrs:{"labels":_vm.productLabels,"datasets":[
               {
                  data: _vm.productDatasets,
                  backgroundColor: '#01a3bb73',
                  label: 'P&L',
                  borderColor:'#01A3BB',
                  lineTension:0
                }
              ],"options":{ maintainAspectRatio: false ,
                 scales: {
                  yAxes: [{
                  min: 40

          }],
      }}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }