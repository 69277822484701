<template>
  <CCard class="active-players">
    <CCardBody>
      <CRow>
        <CCol sm="6" lg="6">
          <h4  class="card-title mb-3">Rounds </h4>
          <CDataTable
              class="zq--table--dashboard zq--table"
              hover
              :items="products"
              :fields="productFields"
              head-color="light"
              no-sorting
          >
            <template #rank-header>
              <div>Top 25 Member Groups</div>
            </template>
            <td slot="memberGroups" slot-scope="{item}">
              <div>
                {{item["Member Groups"]}}
              </div>
            </td>
            <td slot="rounds" slot-scope="{item}">
              <div>
                {{item[status]}}
              </div>
            </td>
          </CDataTable>
        </CCol>
        <CCol sm="6" lg="6">

          <CChartHorizontalBar
              style="height:500px"
              type='horizontalBar'
              :labels="productLabels"
              :datasets=" [{
                label: 'Rounds',
                data: productDatasets,
                    backgroundColor: '#4b5bb7',
                }]"

              :options="{
                maintainAspectRatio: false ,
                scales: {
                yAxes: [{
                 gridLines:{
                        drawBorder: false,
                        display:false,
                      },
                  },
                ],
                XAxes: [{
                ticks: {
                        min:0,
                        max:1400,
                  }
            }]
        }}"
          ></CChartHorizontalBar>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>

</template>

<script>
import {CChartHorizontalBar} from '@coreui/vue-chartjs'
export default {
  name: "rounds",
  components:{
    CChartHorizontalBar
  },
  props: { status: String,},
  data(){
    return{
      products:[{"rank":1,"Member Groups":"CL","Last Hour":7500,"Last 3 Hours":22500,"Last 6 Hours":67500,"Last 12 Hours":202500,"Last 24 Hours":337500,"Last 48 Hours":607500,"Last Week":3037500,"Last 28 Days":13365000},
        {"rank":2,"Member Groups":"relax[317]","Last Hour":7125,"Last 3 Hours":21375,"Last 6 Hours":64125,"Last 12 Hours":192375,"Last 24 Hours":320625,"Last 48 Hours":577125,"Last Week":2885625,"Last 28 Days":12696750},
        {"rank":3,"Member Groups":"operator[random]","Last Hour":6750,"Last 3 Hours":20250,"Last 6 Hours":60750,"Last 12 Hours":182250,"Last 24 Hours":303750,"Last 48 Hours":546750,"Last Week":2733750,"Last 28 Days":12028500},
        {"rank":4,"Member Groups":"environment[LUX]","Last Hour":6625,"Last 3 Hours":19875,"Last 6 Hours":59625,"Last 12 Hours":178875,"Last 24 Hours":298125,"Last 48 Hours":536625,"Last Week":2683125,"Last 28 Days":11805750},
        {"rank":5,"Member Groups":"brandId[20]","Last Hour":6375,"Last 3 Hours":19125,"Last 6 Hours":57375,"Last 12 Hours":172125,"Last 24 Hours":286875,"Last 48 Hours":516375,"Last Week":2581875,"Last 28 Days":11360250},
        {"rank":6,"Member Groups":"country[CH]","Last Hour":6250,"Last 3 Hours":18750,"Last 6 Hours":56250,"Last 12 Hours":168750,"Last 24 Hours":281250,"Last 48 Hours":506250,"Last Week":2531250,"Last 28 Days":11137500},
        {"rank":7,"Member Groups":"parentPartner","Last Hour":4800,"Last 3 Hours":14400,"Last 6 Hours":43200,"Last 12 Hours":129600,"Last 24 Hours":216000,"Last 48 Hours":388800,"Last Week":1944000,"Last 28 Days":8553600},
        {"rank":8,"Member Groups":"free_player","Last Hour":4550,"Last 3 Hours":13650,"Last 6 Hours":40950,"Last 12 Hours":122850,"Last 24 Hours":204750,"Last 48 Hours":368550,"Last Week":1842750,"Last 28 Days":8108100},
        {"rank":9,"Member Groups":"demo","Last Hour":4500,"Last 3 Hours":13500,"Last 6 Hours":40500,"Last 12 Hours":121500,"Last 24 Hours":202500,"Last 48 Hours":364500,"Last Week":1822500,"Last 28 Days":8019000},
        {"rank":10,"Member Groups":"relax[150]","Last Hour":4250,"Last 3 Hours":12750,"Last 6 Hours":38250,"Last 12 Hours":114750,"Last 24 Hours":191250,"Last 48 Hours":344250,"Last Week":1721250,"Last 28 Days":7573500},
        {"rank":11,"Member Groups":"games","Last Hour":4100,"Last 3 Hours":12300,"Last 6 Hours":36900,"Last 12 Hours":110700,"Last 24 Hours":184500,"Last 48 Hours":332100,"Last Week":1660500,"Last 28 Days":7306200},
        {"rank":12,"Member Groups":"country[RU]","Last Hour":3000,"Last 3 Hours":9000,"Last 6 Hours":27000,"Last 12 Hours":81000,"Last 24 Hours":135000,"Last 48 Hours":243000,"Last Week":1215000,"Last 28 Days":5346000},
        {"rank":13,"Member Groups":"relax[240]","Last Hour":2775,"Last 3 Hours":8325,"Last 6 Hours":24975,"Last 12 Hours":74925,"Last 24 Hours":124875,"Last 48 Hours":224775,"Last Week":1123875,"Last 28 Days":4945050},
        {"rank":14,"Member Groups":"relax[123]","Last Hour":2700,"Last 3 Hours":8100,"Last 6 Hours":24300,"Last 12 Hours":72900,"Last 24 Hours":121500,"Last 48 Hours":218700,"Last Week":1093500,"Last 28 Days":4811400},
        {"rank":15,"Member Groups":"country[FI]","Last Hour":2475,"Last 3 Hours":7425,"Last 6 Hours":22275,"Last 12 Hours":66825,"Last 24 Hours":111375,"Last 48 Hours":200475,"Last Week":1002375,"Last 28 Days":4410450},
        {"rank":16,"Member Groups":"relax[110]","Last Hour":2200,"Last 3 Hours":6600,"Last 6 Hours":19800,"Last 12 Hours":59400,"Last 24 Hours":99000,"Last 48 Hours":178200,"Last Week":891000,"Last 28 Days":3920400},
        {"rank":17,"Member Groups":"country[SE]","Last Hour":1950,"Last 3 Hours":5850,"Last 6 Hours":17550,"Last 12 Hours":52650,"Last 24 Hours":87750,"Last 48 Hours":157950,"Last Week":789750,"Last 28 Days":3474900},
        {"rank":18,"Member Groups":"relax[11]","Last Hour":1925,"Last 3 Hours":5775,"Last 6 Hours":17325,"Last 12 Hours":51975,"Last 24 Hours":86625,"Last 48 Hours":155925,"Last Week":779625,"Last 28 Days":3430350},
        {"rank":19,"Member Groups":"relax[222]","Last Hour":1550,"Last 3 Hours":4650,"Last 6 Hours":13950,"Last 12 Hours":41850,"Last 24 Hours":69750,"Last 48 Hours":125550,"Last Week":627750,"Last 28 Days":2762100},
        {"rank":20,"Member Groups":"country[NL]","Last Hour":1375,"Last 3 Hours":4125,"Last 6 Hours":12375,"Last 12 Hours":37125,"Last 24 Hours":61875,"Last 48 Hours":111375,"Last Week":556875,"Last 28 Days":2450250},
        {"rank":21,"Member Groups":"country[MT]","Last Hour":1075,"Last 3 Hours":3225,"Last 6 Hours":9675,"Last 12 Hours":29025,"Last 24 Hours":48375,"Last 48 Hours":87075,"Last Week":435375,"Last 28 Days":1915650},
        {"rank":22,"Member Groups":"relax[5]","Last Hour":1000,"Last 3 Hours":3000,"Last 6 Hours":9000,"Last 12 Hours":27000,"Last 24 Hours":45000,"Last 48 Hours":81000,"Last Week":405000,"Last 28 Days":1782000},
        {"rank":23,"Member Groups":"relax[73][TR]","Last Hour":975,"Last 3 Hours":2925,"Last 6 Hours":8775,"Last 12 Hours":26325,"Last 24 Hours":43875,"Last 48 Hours":78975,"Last Week":394875,"Last 28 Days":1737450},
        {"rank":24,"Member Groups":"country[BY]","Last Hour":875,"Last 3 Hours":2625,"Last 6 Hours":7875,"Last 12 Hours":23625,"Last 24 Hours":39375,"Last 48 Hours":70875,"Last Week":354375,"Last 28 Days":1559250},
        {"rank":25,"Member Groups":"relax[101]","Last Hour":750,"Last 3 Hours":2250,"Last 6 Hours":6750,"Last 12 Hours":20250,"Last 24 Hours":33750,"Last 48 Hours":60750,"Last Week":303750,"Last 28 Days":1336500}],

      productFields: [{key: 'rank'},
        {key: 'memberGroups'},
        {key: 'rounds'},
      ],
    }
    },
  created(){

  },
  computed: {
    productDatasets() {
      var currentStatus = this.status;
      var p=[];
      this.products.forEach(function(product) {
        p.push(product[currentStatus]);
      });
      return p;
    },

    productLabels(){
      var p=[];
      this.products.forEach(function(product) {
        p.push( product["Member Groups"]+"  "+ product["rank"]);
      });
      return p;
    }
  },


}
</script>

<style scoped>
#active-table{
  height:500px;
  overflow-x: auto;
}
</style>