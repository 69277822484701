<template>
  <CCard class="active-players">
    <CCardBody>
      <CRow>
        <CCol sm="6" lg="6">
          <h4  class="card-title mb-3">Rounds </h4>
          <CDataTable
              class="zq--table--dashboard zq--table"
              hover
              :items="products"
              :fields="productFields"
              head-color="light"
              no-sorting
          >
            <template #rank-header>
              <div>Top 25 Games</div>
            </template>
            <td slot="productName" slot-scope="{item}">
              <div>
                {{item["product name"]}}
              </div>
            </td>
            <td slot="rounds" slot-scope="{item}">
              <div>
                {{item[status]}}
              </div>
            </td>
          </CDataTable>
        </CCol>
        <CCol sm="6" lg="6">

          <CChartHorizontalBar
              style="height:500px"
              type='horizontalBar'
              :labels="productLabels"
              :datasets=" [{
                label: 'Rounds',
                data: productDatasets,
                    backgroundColor: '#4b5bb7',
                }]"

              :options="{
                maintainAspectRatio: false ,
                scales: {
                yAxes: [{
                 gridLines:{
                        drawBorder: false,
                        display:false,
                      },
                  },
                ],
                XAxes: [{
                ticks: {
                        min:0,
                        max:1400,
                  }
            }]
        }}"
          ></CChartHorizontalBar>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>

</template>

<script>
import {CChartHorizontalBar} from '@coreui/vue-chartjs'
export default {
  name: "rounds",
  components:{
    CChartHorizontalBar
  },
  props: { status: String,},
  data(){
    return{
      products:[{"rank":1,"product name":"fruits","Last Hour":30750,"Last 3 Hours":92250,"Last 6 Hours":276750,"Last 12 Hours":830250,"Last 24 Hours":1383750,"Last 48 Hours":2490750,"Last Week":12453750,"Last 28 Days":54796500},
        {"rank":2,"product name":"doubleroulette","Last Hour":27500,"Last 3 Hours":82500,"Last 6 Hours":247500,"Last 12 Hours":742500,"Last 24 Hours":1237500,"Last 48 Hours":2227500,"Last Week":11137500,"Last 28 Days":49005000},
        {"rank":3,"product name":"panthersreign","Last Hour":27375,"Last 3 Hours":82125,"Last 6 Hours":246375,"Last 12 Hours":739125,"Last 24 Hours":1231875,"Last 48 Hours":2217375,"Last Week":11086875,"Last 28 Days":48782250},
        {"rank":4,"product name":"ghostglyph","Last Hour":21000,"Last 3 Hours":63000,"Last 6 Hours":189000,"Last 12 Hours":567000,"Last 24 Hours":945000,"Last 48 Hours":1701000,"Last Week":8505000,"Last 28 Days":37422000},
        {"rank":5,"product name":"pimpelipom","Last Hour":15125,"Last 3 Hours":45375,"Last 6 Hours":136125,"Last 12 Hours":408375,"Last 24 Hours":680625,"Last 48 Hours":1225125,"Last Week":6125625,"Last 28 Days":26952750},
        {"rank":6,"product name":"moneytrain","Last Hour":14975,"Last 3 Hours":44925,"Last 6 Hours":134775,"Last 12 Hours":404325,"Last 24 Hours":673875,"Last 48 Hours":1212975,"Last Week":6064875,"Last 28 Days":26685450},
        {"rank":7,"product name":"normans","Last Hour":13575,"Last 3 Hours":40725,"Last 6 Hours":122175,"Last 12 Hours":366525,"Last 24 Hours":610875,"Last 48 Hours":1099575,"Last Week":5497875,"Last 28 Days":24190650},
        {"rank":8,"product name":"sunset","Last Hour":13350,"Last 3 Hours":40050,"Last 6 Hours":120150,"Last 12 Hours":360450,"Last 24 Hours":600750,"Last 48 Hours":1081350,"Last Week":5406750,"Last 28 Days":23789700},
        {"rank":9,"product name":"jokerjackpot","Last Hour":3075,"Last 3 Hours":9225,"Last 6 Hours":27675,"Last 12 Hours":83025,"Last 24 Hours":138375,"Last 48 Hours":249075,"Last Week":1245375,"Last 28 Days":5479650},
        {"rank":10,"product name":"towertumble","Last Hour":2550,"Last 3 Hours":7650,"Last 6 Hours":22950,"Last 12 Hours":68850,"Last 24 Hours":114750,"Last 48 Hours":206550,"Last Week":1032750,"Last 28 Days":4544100},
        {"rank":11,"product name":"goldlab","Last Hour":2250,"Last 3 Hours":6750,"Last 6 Hours":20250,"Last 12 Hours":60750,"Last 24 Hours":101250,"Last 48 Hours":182250,"Last Week":911250,"Last 28 Days":4009500},
        {"rank":12,"product name":"bbw","Last Hour":2125,"Last 3 Hours":6375,"Last 6 Hours":19125,"Last 12 Hours":57375,"Last 24 Hours":95625,"Last 48 Hours":172125,"Last Week":860625,"Last 28 Days":3786750},
        {"rank":13,"product name":"wildsociety","Last Hour":2100,"Last 3 Hours":6300,"Last 6 Hours":18900,"Last 12 Hours":56700,"Last 24 Hours":94500,"Last 48 Hours":170100,"Last Week":850500,"Last 28 Days":3742200},
        {"rank":14,"product name":"megaflip","Last Hour":1800,"Last 3 Hours":5400,"Last 6 Hours":16200,"Last 12 Hours":48600,"Last 24 Hours":81000,"Last 48 Hours":145800,"Last Week":729000,"Last 28 Days":3207600},
        {"rank":15,"product name":"musketeers","Last Hour":1500,"Last 3 Hours":4500,"Last 6 Hours":13500,"Last 12 Hours":40500,"Last 24 Hours":67500,"Last 48 Hours":121500,"Last Week":607500,"Last 28 Days":2673000},
        {"rank":16,"product name":"tigersglory","Last Hour":1375,"Last 3 Hours":4125,"Last 6 Hours":12375,"Last 12 Hours":37125,"Last 24 Hours":61875,"Last 48 Hours":111375,"Last Week":556875,"Last 28 Days":2450250},
        {"rank":17,"product name":"polarpaws","Last Hour":1250,"Last 3 Hours":3750,"Last 6 Hours":11250,"Last 12 Hours":33750,"Last 24 Hours":56250,"Last 48 Hours":101250,"Last Week":506250,"Last 28 Days":2227500},
        {"rank":18,"product name":"jamminjars","Last Hour":1200,"Last 3 Hours":3600,"Last 6 Hours":10800,"Last 12 Hours":32400,"Last 24 Hours":54000,"Last 48 Hours":97200,"Last Week":486000,"Last 28 Days":2138400},
        {"rank":19,"product name":"mars","Last Hour":1175,"Last 3 Hours":3525,"Last 6 Hours":10575,"Last 12 Hours":31725,"Last 24 Hours":52875,"Last 48 Hours":95175,"Last Week":475875,"Last 28 Days":2093850},
        {"rank":20,"product name":"redtemple2","Last Hour":1000,"Last 3 Hours":3000,"Last 6 Hours":9000,"Last 12 Hours":27000,"Last 24 Hours":45000,"Last 48 Hours":81000,"Last Week":405000,"Last 28 Days":1782000},
        {"rank":21,"product name":"ironbank","Last Hour":800,"Last 3 Hours":2400,"Last 6 Hours":7200,"Last 12 Hours":21600,"Last 24 Hours":36000,"Last 48 Hours":64800,"Last Week":324000,"Last 28 Days":1425600},
        {"rank":22,"product name":"slotvegas","Last Hour":750,"Last 3 Hours":2250,"Last 6 Hours":6750,"Last 12 Hours":20250,"Last 24 Hours":33750,"Last 48 Hours":60750,"Last Week":303750,"Last 28 Days":1336500},
        {"rank":23,"product name":"volsunga","Last Hour":700,"Last 3 Hours":2100,"Last 6 Hours":6300,"Last 12 Hours":18900,"Last 24 Hours":31500,"Last 48 Hours":56700,"Last Week":283500,"Last 28 Days":1247400},
        {"rank":24,"product name":"saunapeli","Last Hour":675,"Last 3 Hours":2025,"Last 6 Hours":6075,"Last 12 Hours":18225,"Last 24 Hours":30375,"Last 48 Hours":54675,"Last Week":273375,"Last 28 Days":1202850},
        {"rank":25,"product name":"highvoltage","Last Hour":625,"Last 3 Hours":1875,"Last 6 Hours":5625,"Last 12 Hours":16875,"Last 24 Hours":28125,"Last 48 Hours":50625,"Last Week":253125,"Last 28 Days":1113750}],
      productFields: [
        {key: 'rank'},
        {key: 'productName'},
        {key: 'rounds'},
      ],
    }
    },
  created(){

  },
  computed: {
    productDatasets() {
      var currentStatus = this.status;
      var p=[];
      this.products.forEach(function(product) {
        p.push(product[currentStatus]);
      });
      return p;
    },

    productLabels(){
      var p=[];
      this.products.forEach(function(product) {
        p.push( product["product name"]+"  "+ product["rank"]);
      });
      return p;
    }
  },


}
</script>
