<template>
  <CCard class="active-players">
    <CCardBody>
      <CRow>
        <CCol sm="6" lg="6">
          <h4  class="card-title mb-3">Turnover </h4>
          <CDataTable
              class="zq--table--dashboard zq--table"
              hover
              :items="products"
              :fields="productFields"
              head-color="light"
              no-sorting
          >
            <template #rank-header>
              <div>Top 25 Members</div>
            </template>
            <td slot="members" slot-scope="{item}">
              <router-link :to="{name: `PreviewMember`, params: {id: item[`Members`]} }">
                {{item["Members"]}}
              </router-link>
            </td>
            <td slot="turnover" slot-scope="{item}">
                {{item[status]}}
            </td>
          </CDataTable>
        </CCol>
        <CCol sm="6" lg="6">

          <CChartHorizontalBar
              style="height:500px"
              type='horizontalBar'
              :labels="productLabels"
              :datasets=" [{
                label: 'Turnover',
                data: productDatasets,
                backgroundColor: '#007dfb',
                
                }]"

              :options="{
                maintainAspectRatio: false ,
                scales: {
                yAxes: [{
                 gridLines:{
                        drawBorder: false,
                        display:false,
                      },
                  },
                ],
                XAxes: [{
                ticks: {
                        min:0,
                        max:1400,
                  }
            }]
        }}"
          ></CChartHorizontalBar>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>

</template>

<script>
import {CChartHorizontalBar} from '@coreui/vue-chartjs'
export default {
  name: "turnover",
  components:{
    CChartHorizontalBar
  },
  props: { status: String,},
  data(){
    return{
      products:[{"rank":1,"Members":"-ehuzncBnBVJ8S2bVkdD","Last Hour":330,"Last 3 Hours":990,"Last 6 Hours":2970,"Last 12 Hours":8910,"Last 24 Hours":14850,"Last 48 Hours":26730,"Last Week":133650,"Last 28 Days":588060},
        {"rank":2,"Members":"ouhdzncBnBVJ8S2b7Ufw","Last Hour":280,"Last 3 Hours":840,"Last 6 Hours":2520,"Last 12 Hours":7560,"Last 24 Hours":12600,"Last 48 Hours":22680,"Last Week":113400,"Last 28 Days":498960},
        {"rank":3,"Members":"mehczncBnBVJ8S2bREcG","Last Hour":200,"Last 3 Hours":600,"Last 6 Hours":1800,"Last 12 Hours":5400,"Last 24 Hours":9000,"Last 48 Hours":16200,"Last Week":81000,"Last 28 Days":356400},
        {"rank":4,"Members":"4ug0zncBnBVJ8S2bmEYN","Last Hour":197,"Last 3 Hours":591,"Last 6 Hours":1773,"Last 12 Hours":5319,"Last 24 Hours":8865,"Last 48 Hours":15957,"Last Week":79785,"Last 28 Days":351054},
        {"rank":5,"Members":"0egxzncBnBVJ8S2bsUac","Last Hour":150,"Last 3 Hours":450,"Last 6 Hours":1350,"Last 12 Hours":4050,"Last 24 Hours":6750,"Last 48 Hours":12150,"Last Week":60750,"Last 28 Days":267300},
        {"rank":6,"Members":"w-gwzncBnBVJ8S2bB0bm","Last Hour":149,"Last 3 Hours":447,"Last 6 Hours":1341,"Last 12 Hours":4023,"Last 24 Hours":6705,"Last 48 Hours":12069,"Last Week":60345,"Last 28 Days":265518},
        {"rank":7,"Members":"DOgOzncBnBVJ8S2bJEZd","Last Hour":130,"Last 3 Hours":390,"Last 6 Hours":1170,"Last 12 Hours":3510,"Last 24 Hours":5850,"Last 48 Hours":10530,"Last Week":52650,"Last 28 Days":231660},
        {"rank":8,"Members":"bW5cjHcBZJqtaNvN14NI","Last Hour":111,"Last 3 Hours":333,"Last 6 Hours":999,"Last 12 Hours":2997,"Last 24 Hours":4995,"Last 48 Hours":8991,"Last Week":44955,"Last 28 Days":197802},
        {"rank":9,"Members":"Y25ajHcBZJqtaNvNHIMO","Last Hour":98,"Last 3 Hours":294,"Last 6 Hours":882,"Last 12 Hours":2646,"Last 24 Hours":4410,"Last 48 Hours":7938,"Last Week":39690,"Last 28 Days":174636},
        {"rank":10,"Members":"X25ZjHcBZJqtaNvNAIPV","Last Hour":97,"Last 3 Hours":291,"Last 6 Hours":873,"Last 12 Hours":2619,"Last 24 Hours":4365,"Last 48 Hours":7857,"Last Week":39285,"Last 28 Days":172854},
        {"rank":11,"Members":"UW5VjHcBZJqtaNvNyYNw","Last Hour":95,"Last 3 Hours":285,"Last 6 Hours":855,"Last 12 Hours":2565,"Last 24 Hours":4275,"Last 48 Hours":7695,"Last Week":38475,"Last 28 Days":169290},
        {"rank":12,"Members":"8W48jHcBZJqtaNvNtIKp","Last Hour":90,"Last 3 Hours":270,"Last 6 Hours":810,"Last 12 Hours":2430,"Last 24 Hours":4050,"Last 48 Hours":7290,"Last Week":36450,"Last 28 Days":160380},
        {"rank":13,"Members":"3242jHcBZJqtaNvNwoK_","Last Hour":80,"Last 3 Hours":240,"Last 6 Hours":720,"Last 12 Hours":2160,"Last 24 Hours":3600,"Last 48 Hours":6480,"Last Week":32400,"Last 28 Days":142560},
        {"rank":14,"Members":"2W41jHcBZJqtaNvNHYIs","Last Hour":83,"Last 3 Hours":249,"Last 6 Hours":747,"Last 12 Hours":2241,"Last 24 Hours":3735,"Last 48 Hours":6723,"Last Week":33615,"Last 28 Days":147906},
        {"rank":15,"Members":"1G40jHcBZJqtaNvNdoLl","Last Hour":81,"Last 3 Hours":243,"Last 6 Hours":729,"Last 12 Hours":2187,"Last 24 Hours":3645,"Last 48 Hours":6561,"Last Week":32805,"Last 28 Days":144342},
        {"rank":16,"Members":"j24mjHcBZJqtaNvNwIIj","Last Hour":70,"Last 3 Hours":210,"Last 6 Hours":630,"Last 12 Hours":1890,"Last 24 Hours":3150,"Last 48 Hours":5670,"Last Week":28350,"Last 28 Days":124740},
        {"rank":17,"Members":"nm3nXXcBZJqtaNvN9NYW","Last Hour":50,"Last 3 Hours":150,"Last 6 Hours":450,"Last 12 Hours":1350,"Last 24 Hours":2250,"Last 48 Hours":4050,"Last Week":20250,"Last 28 Days":89100},
        {"rank":18,"Members":"Pm2VXXcBZJqtaNvNLNUT","Last Hour":48,"Last 3 Hours":144,"Last 6 Hours":432,"Last 12 Hours":1296,"Last 24 Hours":2160,"Last 48 Hours":3888,"Last Week":19440,"Last 28 Days":85536},
        {"rank":19,"Members":"LW2PXXcBZJqtaNvNhtVH","Last Hour":47,"Last 3 Hours":141,"Last 6 Hours":423,"Last 12 Hours":1269,"Last 24 Hours":2115,"Last 48 Hours":3807,"Last Week":19035,"Last 28 Days":83754},
        {"rank":20,"Members":"zm0uT3cBZJqtaNvNPqK1","Last Hour":40,"Last 3 Hours":120,"Last 6 Hours":360,"Last 12 Hours":1080,"Last 24 Hours":1800,"Last 48 Hours":3240,"Last Week":16200,"Last 28 Days":71280},
        {"rank":21,"Members":"Bm0bSXcBZJqtaNvNvYk_","Last Hour":32,"Last 3 Hours":96,"Last 6 Hours":288,"Last 12 Hours":864,"Last 24 Hours":1440,"Last 48 Hours":2592,"Last Week":12960,"Last 28 Days":57024},
        {"rank":22,"Members":"Ym3xSHcBZJqtaNvNWIgM","Last Hour":30,"Last 3 Hours":90,"Last 6 Hours":270,"Last 12 Hours":810,"Last 24 Hours":1350,"Last 48 Hours":2430,"Last Week":12150,"Last 28 Days":53460},
        {"rank":23,"Members":"Wm3wSHcBZJqtaNvNH4gp","Last Hour":28,"Last 3 Hours":84,"Last 6 Hours":252,"Last 12 Hours":756,"Last 24 Hours":1260,"Last 48 Hours":2268,"Last Week":11340,"Last 28 Days":49896},
        {"rank":24,"Members":"PG3qSHcBZJqtaNvNUojP","Last Hour":27,"Last 3 Hours":81,"Last 6 Hours":243,"Last 12 Hours":729,"Last 24 Hours":1215,"Last 48 Hours":2187,"Last Week":10935,"Last 28 Days":48114},
        {"rank":25,"Members":"N23oSHcBZJqtaNvNvoj8","Last Hour":25,"Last 3 Hours":75,"Last 6 Hours":225,"Last 12 Hours":675,"Last 24 Hours":1125,"Last 48 Hours":2025,"Last Week":10125,"Last 28 Days":44550}],
      productFields: [
        {key: 'rank'},
        {key: 'members'},
        {key: 'turnover'},
      ],
    }
    },
  created(){

  },
  computed: {
    productDatasets() {
      var currentStatus = this.status;
      var p=[];
      this.products.forEach(function(product) {
        p.push(product[currentStatus]);
      });
      return p;
    },

    productLabels(){
      var p=[];
      this.products.forEach(function(product) {
        p.push( product["Members"]+"  "+ product["rank"]);
      });
      return p;
    }
  },
}
</script>
