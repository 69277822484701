<template>
  <CCard class="active-players">
      <CCardBody>
        <CRow>
          <CCol sm="6" lg="6">
            <h4 class="card-title mb-3">P&L</h4>
            <CDataTable
                class="zq--table--dashboard zq--table"
                hover
                :items="products"
                :fields="productFields"
                head-color="light"
                no-sorting
            >
              <template #rank-header>
                <div>Top 25 Games</div>
              </template>
              <template #pNL-header>
                <div>P&L</div>
              </template>
              <td slot="memberGroups" slot-scope="{item}">
                <div>
                  {{item["Member Groups"]}}
                </div>
              </td>
              <td slot="pNL" slot-scope="{item}">
                <div>
                  {{item[status]}}
                </div>
              </td>
            </CDataTable>
          </CCol>

          <CCol sm="6" lg="6">

            <CChartLine
                style="height:400px"
                :labels=productLabels
                :datasets="[
                 {
                    data: productDatasets,
                    backgroundColor: '#01a3bb73',
                    label: 'P&L',
                    borderColor:'#01A3BB',
                    lineTension:0
                  }
                ]"

                :options="{ maintainAspectRatio: false ,
                   scales: {
                    yAxes: [{
                    min: 40

            }],
        }}"
            ></CChartLine>
          </CCol>
        </CRow>
      </CCardBody>
  </CCard>

</template>

<script>
import { CChartLine } from '@coreui/vue-chartjs'
export default {
  name: "ProfitLoss",
  components:{
    CChartLine
  },
  props: { status: String,},
  data(){
    return{
      products:[{"rank":1,"Member Groups":"CL","Last Hour":5320,"Last 3 Hours":15960,"Last 6 Hours":47880,"Last 12 Hours":143640,"Last 24 Hours":239400,"Last 48 Hours":430920,"Last Week":2154600,"Last 28 Days":9480240},
        {"rank":2,"Member Groups":"relax[317]","Last Hour":4850,"Last 3 Hours":14550,"Last 6 Hours":43650,"Last 12 Hours":130950,"Last 24 Hours":218250,"Last 48 Hours":392850,"Last Week":1964250,"Last 28 Days":8642700},
        {"rank":3,"Member Groups":"operator[random]","Last Hour":4750,"Last 3 Hours":14250,"Last 6 Hours":42750,"Last 12 Hours":128250,"Last 24 Hours":213750,"Last 48 Hours":384750,"Last Week":1923750,"Last 28 Days":8464500},
        {"rank":4,"Member Groups":"environment[LUX]","Last Hour":4520,"Last 3 Hours":13560,"Last 6 Hours":40680,"Last 12 Hours":122040,"Last 24 Hours":203400,"Last 48 Hours":366120,"Last Week":1830600,"Last 28 Days":8054640},
        {"rank":5,"Member Groups":"brandId[20]","Last Hour":4320,"Last 3 Hours":12960,"Last 6 Hours":38880,"Last 12 Hours":116640,"Last 24 Hours":194400,"Last 48 Hours":349920,"Last Week":1749600,"Last 28 Days":7698240},
        {"rank":6,"Member Groups":"country[CH]","Last Hour":3990,"Last 3 Hours":11970,"Last 6 Hours":35910,"Last 12 Hours":107730,"Last 24 Hours":179550,"Last 48 Hours":323190,"Last Week":1615950,"Last 28 Days":7110180},
        {"rank":7,"Member Groups":"parentPartner","Last Hour":3820,"Last 3 Hours":11460,"Last 6 Hours":34380,"Last 12 Hours":103140,"Last 24 Hours":171900,"Last 48 Hours":309420,"Last Week":1547100,"Last 28 Days":6807240},
        {"rank":8,"Member Groups":"free_player","Last Hour":3410,"Last 3 Hours":10230,"Last 6 Hours":30690,"Last 12 Hours":92070,"Last 24 Hours":153450,"Last 48 Hours":276210,"Last Week":1381050,"Last 28 Days":6076620},
        {"rank":9,"Member Groups":"demo","Last Hour":3120,"Last 3 Hours":9360,"Last 6 Hours":28080,"Last 12 Hours":84240,"Last 24 Hours":140400,"Last 48 Hours":252720,"Last Week":1263600,"Last 28 Days":5559840},
        {"rank":10,"Member Groups":"relax[150]","Last Hour":2980,"Last 3 Hours":8940,"Last 6 Hours":26820,"Last 12 Hours":80460,"Last 24 Hours":134100,"Last 48 Hours":241380,"Last Week":1206900,"Last 28 Days":5310360},
        {"rank":11,"Member Groups":"games","Last Hour":2800,"Last 3 Hours":8400,"Last 6 Hours":25200,"Last 12 Hours":75600,"Last 24 Hours":126000,"Last 48 Hours":226800,"Last Week":1134000,"Last 28 Days":4989600},
        {"rank":12,"Member Groups":"country[RU]","Last Hour":2770,"Last 3 Hours":8310,"Last 6 Hours":24930,"Last 12 Hours":74790,"Last 24 Hours":124650,"Last 48 Hours":224370,"Last Week":1121850,"Last 28 Days":4936140},
        {"rank":13,"Member Groups":"relax[240]","Last Hour":2600,"Last 3 Hours":7800,"Last 6 Hours":23400,"Last 12 Hours":70200,"Last 24 Hours":117000,"Last 48 Hours":210600,"Last Week":1053000,"Last 28 Days":4633200},
        {"rank":14,"Member Groups":"relax[123]","Last Hour":2505,"Last 3 Hours":7515,"Last 6 Hours":22545,"Last 12 Hours":67635,"Last 24 Hours":112725,"Last 48 Hours":202905,"Last Week":1014525,"Last 28 Days":4463910},
        {"rank":15,"Member Groups":"country[FI]","Last Hour":2300,"Last 3 Hours":6900,"Last 6 Hours":20700,"Last 12 Hours":62100,"Last 24 Hours":103500,"Last 48 Hours":186300,"Last Week":931500,"Last 28 Days":4098600},
        {"rank":16,"Member Groups":"relax[110]","Last Hour":2202,"Last 3 Hours":6606,"Last 6 Hours":19818,"Last 12 Hours":59454,"Last 24 Hours":99090,"Last 48 Hours":178362,"Last Week":891810,"Last 28 Days":3923964},
        {"rank":17,"Member Groups":"country[SE]","Last Hour":1900,"Last 3 Hours":5700,"Last 6 Hours":17100,"Last 12 Hours":51300,"Last 24 Hours":85500,"Last 48 Hours":153900,"Last Week":769500,"Last 28 Days":3385800},
        {"rank":18,"Member Groups":"relax[11]","Last Hour":1708,"Last 3 Hours":5124,"Last 6 Hours":15372,"Last 12 Hours":46116,"Last 24 Hours":76860,"Last 48 Hours":138348,"Last Week":691740,"Last 28 Days":3043656},
        {"rank":19,"Member Groups":"relax[222]","Last Hour":1603,"Last 3 Hours":4809,"Last 6 Hours":14427,"Last 12 Hours":43281,"Last 24 Hours":72135,"Last 48 Hours":129843,"Last Week":649215,"Last 28 Days":2856546},
        {"rank":20,"Member Groups":"country[NL]","Last Hour":1320,"Last 3 Hours":3960,"Last 6 Hours":11880,"Last 12 Hours":35640,"Last 24 Hours":59400,"Last 48 Hours":106920,"Last Week":534600,"Last 28 Days":2352240},
        {"rank":21,"Member Groups":"country[MT]","Last Hour":1120,"Last 3 Hours":3360,"Last 6 Hours":10080,"Last 12 Hours":30240,"Last 24 Hours":50400,"Last 48 Hours":90720,"Last Week":453600,"Last 28 Days":1995840},
        {"rank":22,"Member Groups":"relax[5]","Last Hour":1101,"Last 3 Hours":3303,"Last 6 Hours":9909,"Last 12 Hours":29727,"Last 24 Hours":49545,"Last 48 Hours":89181,"Last Week":445905,"Last 28 Days":1961982},
        {"rank":23,"Member Groups":"relax[73][TR]","Last Hour":980,"Last 3 Hours":2940,"Last 6 Hours":8820,"Last 12 Hours":26460,"Last 24 Hours":44100,"Last 48 Hours":79380,"Last Week":396900,"Last 28 Days":1746360},
        {"rank":24,"Member Groups":"country[BY]","Last Hour":750,"Last 3 Hours":2250,"Last 6 Hours":6750,"Last 12 Hours":20250,"Last 24 Hours":33750,"Last 48 Hours":60750,"Last Week":303750,"Last 28 Days":1336500},
        {"rank":25,"Member Groups":"relax[101]","Last Hour":600,"Last 3 Hours":1800,"Last 6 Hours":5400,"Last 12 Hours":16200,"Last 24 Hours":27000,"Last 48 Hours":48600,"Last Week":243000,"Last 28 Days":1069200}],
      productFields: [
        {key: 'rank'},
        {key: 'memberGroups'},
        {key: 'pNL'},
      ],
    }
    },
  created(){

  },
  computed: {
    productDatasets() {
      var currentStatus = this.status;
      var p=[];
      this.products.forEach(function(product) {
        p.push(product[currentStatus]);
      });
      return p;
    },

    productLabels(){
      var p=[];
      this.products.forEach(function(product) {
        p.push( product["Member Groups"]+"  "+ product["rank"]);
      });
      return p;
    }
  },

}
</script>